import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Header } from './components/Header/Header';
import { Quotes } from './components/Quotes/Quotes';

import './Landing.css';
import { Hotels } from './components/Hotels/Hotels';
import { useNavigate } from 'react-router-dom';
import PopularCities from './components/PopularCities/PopularCities';

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Landing',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <Header />

      <div className="landing-container container">
        <div className="landing-hotels">Hotels</div>
        <h1>Browse our hotels</h1>
        <div className="landing-hotels-view">
          <p style={{ fontSize: '22px', marginTop: '0px' }}>
            Enjoy all the hotel amenities for a fraction of an overnight stay.
          </p>
        </div>

        <div className="landing-hotels-list">
          <Hotels />
        </div>
        <div className="hotels-info-list">
          <div className="hotels-info-content">
            <h1>Why Nappr?</h1>
            <div className="hr" />

            <p style={{ marginTop: '50px', fontSize: '30px' }}>
              We are on a daring mission to get you as much rest and relaxation as possible, because feeling sleep deprived is no fun for anyone.
            </p>

            <div className="" style={{ paddingTop: '60px' }}>
              <Quotes
                icon="hotel"
                title="We offer some of the world's largest hotel brands."
                desc="We've got lots of big-name brands like Marriott, Hilton, Hyatt and Sheraton and also many boutique brands if that's your thing."
                alt="Illustration of a pink building with a white roof, four windows, and a central door."
              />
              <Quotes
                icon="money"
                title="It's up to 50% cheaper than standard hotel rates."
                desc="Why pay for a full night at a hotel if you only need one for a few hours? Our rates will save you some coin and then some."
                alt="Icon of a hand holding a money bag, symbolizing wealth or financial management."
              />
              <Quotes
                icon="cities"
                title="We're located in many major cities and airports."
                desc="With over 1,050 hotels across 29 countries and 50 airports, we've got you covered all across the globe!"
                alt="City icon featuring stylized buildings under a clear sky with clouds, representing urban life and architecture."
              />
              <Quotes
                icon="amenities"
                title="You get all the amenities of an overnight stay."
                desc="Want to rest for a bit but also use the pool and exercise room afterwards? Go right ahead!"
                alt="A person in athletic attire running on a treadmill in a well-lit gym environment, focused on their workout."

              />
              <Quotes
                icon="nap"
                title="It's a convenient way to take a nap!"
                desc="Napping improves mood, energy, and cognitive function, leading to better focus and productivity throughout the day."
                alt="A man takes a nap in front of an alarm clock positioned in front of him."
              />
            </div>
          </div>
        </div>

        <div className="landing-apps">
          <div className="landing-apps-container container">
            <div className="landing-apps-intro">
              <h1 style={{ width: '100%' }}>Relaxing stays are a few clicks away</h1>
              <h2 style={{ width: '100%', color:'white' }}>Download our Mobile App today!</h2>
              <div className="landing-apps-buttons">
                <div className="landing-apps-button">
                  <a
                    href="https://apps.apple.com/app/apple-store/id1661113382?pt=118234495&ct=website_footer&mt=8"
                    target="_blank"
                  >
                    <img src={require('../../assets/images/footer/apple-badge.png')} alt="An icon indicating the option to download the Nappr mobile app from the Apple App Store."/>
                  </a>
                </div>

                <div
                  style={{ marginLeft: '0px' }}
                  className="landing-apps-button"
                >
                  <a id="googleButton"
                    href="https://play.google.com/store/apps/details?id=com.napprmobile&utm_source=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                  >
                    <img
                      alt="An icon indicating the option to download the Nappr mobile app from the Google Play Store."
                      className="googlePlayButton"
                      src={require('../../assets/images/footer/google-play-badge.png')}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div style={{ width: '40%' }} className="landing-app">
              <img
                src={require('../../assets/images/footer/mobile-app-demo.png')}
                alt="The Nappr Android mobile app shows a search for hotels in New York City."
                width="400px"
                height="394"
              />
            </div>
          </div>
        </div>

        <div className="AllUseCases">
          <div className="UseCases">
            <h1>Perfect For:</h1>
              <div className="hr" />

              <div className="UseCasesLeft">
                <Quotes
                  icon="airplane"
                  title="Airport Layovers"
                  desc="Beat jet lag the comfortable way"
                  alt="A stylish blue and white airplane gliding effortlessly through the sky, surrounded by fluffy white clouds."
                />
                <Quotes
                  icon="relax"
                  title="Office Stress"
                  desc="Take a break from TPS Reports"
                  alt="A woman enjoys a break from work in the middle of the day"
                />
              </div>
              <div className="UseCasesMid">
                <Quotes
                  icon="student"
                  title="Study Breaks"
                  desc="Help your brain cram for that exam"
                  alt="A student with a backpack ready for class and all the exams headed his way"
                />
                <Quotes
                  icon="hospital"
                  title="Hospital Visits"
                  desc="Stay minutes away from loved ones"
                  alt="A hospital with a recognizable cross on its facade, representing health and healing"
                />
              </div>
              <div className="UseCasesRight">
                <Quotes
                  icon="conference"
                  title="Conferences"
                  desc="Get away from all the fray"
                  alt="A speaker at a podium addresses an audience seated around him"
                />
                <Quotes
                  icon="drive"
                  title="Long Drives"
                  desc="Sleep in a bed, not on the road"
                  alt="A simple car icon featuring luggage on the roof, representing travel and journeys."
                />
              </div>
          </div>
        </div>
        <div id="PopularCities" className="PopularCities">
          <h1>Popular Cities</h1>
          <div className="hr" />

          <PopularCities />
        </div> 
      </div>

    </div>
  );
};
