import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import './Blog.css';
import axiosInstance from '../../utils/axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Spinner } from '../../Components/Spinner/Spinner';
import { useIsMobile } from '../../hooks/IsMobile';
import axios from 'axios';

export const Blog = () => {
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Blog',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    axios
      .get('https://squid-app-322vm.ondigitalocean.app/api/v1/blog')
      .then((res) => {
        if (res?.data?.results) {
          setBlogs(res.data.results);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Nappr Blog</title>
        <meta name="description" content="Some articles that showcase content on sleep, hospitality, travel and everything in between" />
        <meta name="keywords" content="Nappr Blog, sleep blog, hospitality blog, travel blog" />
        <meta name="author" content="Nappr" />
        <meta property="og:title" content="Nappr Blog" />
        <meta property="og:url" content="https://www.nappr.io/blog" />
        <meta property="og:description" content="Some articles that showcase content on sleep, hospitality, travel and everything in between" />
      </Helmet>
      <div className="hotels-search">
        <Search />
      </div>
      {!blogs && loading ? (
        <Spinner />
      ) : !blogs.length && !loading ? (
        <div
          style={{
            minHeight: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          Stay tuned! We're working on creating content that you'll love. Check
          back soon!
        </div>
      ) : (
        <div className="blog-container">
          <h1>Blog</h1>
          <br />
          <hr></hr>
          <div className="stories" style={{ marginTop: '30px' }}>
            {blogs.map((blog) => {
              return (
                <Link
                  className={isMobile ? undefined : 'blog-single'}
                  style={{ color: '#e1e1e1', textDecoration: 'none' }}
                  to={`/blog/${blog.id}/${blog.title.split(' ').join('-')}`}
                  target="_blank"
                  key={blog.id}
                >
                  <div className="storyPic">
                    <div>
                      {blog.thumbnail ? (
                        <img
                          src={blog.thumbnail}
                          style={{
                            height: '250px',
                            width: '350px',
                            objectFit: 'cover',
                          }}
                          alt={blog.alt_text || "Nappr"}
                        />
                      ) : (
                        <img
                          style={{
                            height: '250px',
                            width: '350px',
                            objectFit: 'cover',
                          }}
                          src={require('../../assets/images/homepage/bg/header-mobile-light-blue-gradient.jpg')}
                          alt={blog.alt_text || "Nappr"}
                        />
                      )}
                    </div>
                  </div>
                  <div className="storyInfo">
                    <span className="articleTitle">
                      <div>{blog.title}</div>
                    </span>
                    <br/>
                    <span className="subTitle">
                      <div>{blog.metadescription}</div>
                    </span>
                    <br />
                    <p className="articleDate">{moment(blog.created_at).format('MMMM Do, YYYY')}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
